import React from "react";
import { graphql } from "gatsby";
import {
  keysToCamel,
  resolveImage,
  resolveObjectWithImage,
  mergePrismicPreview,
  resolveCb,
  resolveAuthor,
  resolveFeaturedArticles,
} from "@dataResolvers";
import PressRelease from "../../pageTemplates/PressRelease";

/* eslint react/prop-types: 0 */

export const query = graphql`
  query ($uid: String!) {
    prismicBlogPosts(uid: { eq: $uid }) {
      id
      uid
      url
      data {
        image {
          url
          dimensions {
            width
            height
          }
        }
        heading
        show_date
        show_location
        subheading
        excerpt
        video_url
        date(formatString: "MMM DD, YYYY")
        location
        author {
          document {
            ... on PrismicBlogAuthor {
              data {
                author_name
                author_title
                company
                headshot {
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        copy {
          html
          text
        }
        body {
          ... on PrismicBlogPostsBodyCopy {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicBlogPostsBodyVideoEmbed {
            id
            slice_type
            primary {
              media_url
              caption {
                html
              }
            }
          }
          ... on PrismicBlogPostsBodyImage {
            id
            slice_type
            primary {
              media {
                url
                dimensions {
                  width
                  height
                }
              }
              caption {
                html
              }
              size
            }
          }
          ... on PrismicBlogPostsBodyPullQuote {
            id
            slice_type
            primary {
              quote_content {
                html
                raw
                text
              }
              quote_author
            }
          }
          ... on PrismicBlogPostsBodyQuoteWithHeading {
            id
            slice_type
            primary {
              quote_heading
              quote_copy {
                html
                raw
                text
              }
            }
          }
          ... on PrismicBlogPostsBodyImageWithAccordian {
            id
            slice_type
            primary {
              media {
                url
                dimensions {
                  width
                  height
                }
              }
              accordian_caption {
                html
              }
              accordian_heading
              accordian_content {
                html
              }
              portrait
            }
          }
          ... on PrismicBlogPostsBodyBreakoutBox {
            id
            slice_type
            primary {
              breakout_content {
                html
              }
              breakout_image {
                url
                dimensions {
                  width
                  height
                }
              }
              breakout_image_caption {
                html
              }
              box_heading
              box_content {
                html
              }
              box_position
              horizontal
            }
          }
          ... on PrismicBlogPostsBodyCarousel {
            id
            slice_type
            items {
              carousel_image {
                url
                dimensions {
                  height
                  width
                }
              }
              image_caption {
                html
              }
            }
          }
        }
        meta {
          title
          description
          image {
            url
            dimensions {
              width
              height
            }
          }
        }
        featured_articles {
          article {
            document {
              ... on PrismicPressReleases {
                id
                uid
                url
                data {
                  heading
                  image {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  blog_categories
                  featured_author: author {
                    document {
                      ... on PrismicBlogAuthor {
                        data {
                          author_name
                          author_title
                          company
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicBlogPosts {
                id
                uid
                url
                data {
                  heading
                  image {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  blog_categories
                  featured_author: author {
                    document {
                      ... on PrismicBlogAuthor {
                        data {
                          author_name
                          author_title
                          company
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicBlogPosts }) => {
  const { uid, url, data } = keysToCamel(prismicBlogPosts);
  const {
    image,
    copy,
    meta,
    showDate,
    showLocation,
    videoUrl,
    body,
    author,
    featuredArticles,
    ...rest
  } = data;

  return {
    url,
    uid,
    ...rest,
    videoUrl,
    image: resolveImage(image),
    copy,
    author: resolveAuthor(author?.document?.data),
    body: resolveCb(body),
    showDate: showDate !== false,
    showLocation: showLocation !== false,
    meta: resolveObjectWithImage(meta[0]),
    featured: resolveFeaturedArticles(featuredArticles),
  };
};

const QueryContainer = ({ data: _data, ...rest }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <PressRelease data={data} {...rest} />;
};

export default QueryContainer;
